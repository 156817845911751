import React from "react";
import Pattern from "../Assets/Images/Pattern.png";
import { menuItems } from "../prices/menuItems";
function Menu() {
  console.log(menuItems.filter((item) => item.category[0] === "Appetizers"));
  return (
    <div className="menu-container">
      <div className="menu-pattern-1">
        <img src={Pattern} alt="" />
      </div>
      <div className="nav-blur"></div>
      <div className="menu-pattern-2">
        <img src={Pattern} alt="" />
      </div>
      <div className="container">
        <header className="menu-header">
          <h1 className="page-title">MENU</h1>
          <a className="subheading" href="tel:9058358377">
            905 - 835 - 8377
          </a>
          <a className="subheading" href="tel:9058358177">
            905 - 835 - 8717
          </a>
        </header>
        <div className="discount-info-container">
          <p className="update-disclaimer">Please Order in advance for the holidays</p>
        </div>
        <div className="discount-info-container">
          <p className="discount-title">10 % OFF</p>
          <p className="discount-details">On cash pick up orders over $35 before tax.</p>
        </div>
        <div className="menu-items-container">
          {/* Special Combos */}
          <div className="section-container">
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Special Combos</p>
              <div className="menu-items-line2"></div>
            </div>
            <div className="combo-note">
              <p>(Please order by letter & number)</p>
              <p>All serverd with Egg Roll & Chicken Fried Rice</p>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Special Combo")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
            <p className="side-orders">Side Orders</p>
            <div className="items-container">
              <div className="item-names">
                <p>French Fries</p>
                <p>Pop/Can</p>
              </div>
              <div className="item-prices">
                <p>$4.50</p>
                <p>$1.50</p>
              </div>
            </div>
            <div className="substitution-note">
              <p>*Any substitution $1.00 or up.</p>
            </div>
          </div>
          <div className="section-container">
            {/* Appetizers */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">APPETIZERS</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Appetizers")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Lo Mein (Noodle) */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Lo Mein (Noodle)</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Lo Mein (Noodle)")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Soups */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Soups</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Soup")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Sweet & Sour */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Sweet & Sour</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Sweet & Sour")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Egg Foo Young */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Egg Foo Young</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Egg Foo Young")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Rice */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Rice</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Rice")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Chow Mein */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Chow Mein</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Chow Mein")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Vegetables */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Vegetables</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Vegetables")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Northern Chinese */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Northern Chinese</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Northern Chinese")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Szechuan Dishes */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Szechuan Dishes</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Szechuan")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Pork */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Pork</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Pork")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Beef */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Beef</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Beef")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Poultry */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Poultry (Chicken)</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Poultry (Chicken)")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>

          <div className="section-container">
            {/* Seafood */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Seafood</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Seafood")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Special Suggestions */}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Special Suggestions</p>
              <div className="menu-items-line2"></div>
            </div>
            {menuItems
              .filter((item) => item.category[0] === "Special Suggestions")
              .map((item) => (
                <div className="items-container">
                  <div className="item-names">{item.name}</div>
                  <div className="item-prices">{item.price}</div>
                </div>
              ))}
          </div>
          <div className="section-container">
            {/* Family Dinners*/}
            <div className="menu-items-header">
              <div className="menu-items-line1"></div>
              <p className="menu-items-title">Family Dinners</p>
              <div className="menu-items-line2"></div>
            </div>
            <p className="family-subheading">*Add extra person to any family dinner for $15.00</p>
            {menuItems
              .filter((item) => item.category[0] === "Family Dinners")
              .map((item) => (
                <div className="family-dinner-content">
                  <div className="family-heading">
                    <div className="family-title">{item.name}</div>
                    <div className="family-price">{item.price}</div>
                  </div>
                  <div className="family-items">
                    {item.items.map((dishes) => (
                      <div className="family-item">{dishes}</div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
