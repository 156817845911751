import React from "react";
import Logo from "../Assets/Images/Logo.png";
import Pattern from "../Assets/Images/Pattern.png";
// import HolidayNote from "../Assets/Images/Holiday_Note.png";
// import OpenHours from "../Assets/Images/Open_Hours.png";
// import PhoneNumber from "../Assets/Images/Phone_Number.png";
import Map from "../Assets/Images/Map.png";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="home-container">
        <img src={Pattern} alt="Pattern" className="pattern-1" />
        <div className="container home-content">
          <div className="text-content">
            <div className="home-image-container">
              <img src={Logo} alt="Tie Pink Logo" className="logo-image" />
            </div>
            <div className="address-contact-container">
              <p className="home-address">17 Charlotte St. Port Colborne</p>
              <div className="home-phone-container">
                <a className="home-phone" href="tel:9058358377">
                  905 - 835 - 8377
                </a>
                <a className="home-phone" href="tel:9058358717">
                  905 - 835 - 8717
                </a>
              </div>
              <div className="menu-button-container-lg">
                <NavLink to="/menu" className="menu-lg">
                  MENU
                </NavLink>
              </div>
            </div>
            <div className="hours-title-container">
              <p className="hours-title">OPENING HOURS</p>
            </div>
            <div className="business-info-container">
              <div className="hours-title-container-lg">
                <p className="hours-title-lg">OPENING HOURS</p>
              </div>
              <p className="business-dates">Sunday to Thursday</p>
              <p className="business-hours">3PM - 8PM</p>
              <p className="business-dates">Friday to Saturday</p>
              <p className="business-hours">3PM - 9PM</p>
              <p className="holiday-note">WE ARE OPEN ALL HOLIDAYS</p>
            </div>
            <img className="home-map" src={Map} alt="" />
          </div>
          <div className="menu-button-container">
            <NavLink to="/menu" className="menu">
              MENU
            </NavLink>
          </div>
        </div>
        <img src={Pattern} alt="Pattern" className="pattern-2" />
      </div>
      {/* <div className="update">
        <h2>Holiday Update</h2>
        <p>
          {" "}
          Dear customers, please order days in advanced for the following
          holidays:
        </p>
        <ul>
          <li>Christmas Eve</li>
          <li>Christmas Day</li>
          <li>New Years Eve</li>
          <li>New Years Day</li>
        </ul>
        <p>Thank You and Happy Holidays!</p>
        <p>
          (Please be aware wait times may be longer than usual on these days)
        </p>
      </div> */}
    </>
  );
}

export default Home;
