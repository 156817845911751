export const menuItems = [
  // Combos
  {
    name: "L1. Sweet & Sour Chicken Balls",
    price: "$9.95",
    category: ["Special Combo"],
  },
  {
    name: "L2 Beef Chow Mein",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L3. Beef with Mixed Vegetables",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L4. Sesame Chicken",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L5. Beef with Broccoli",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L6. Beef with Green Pepper",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L7. Chicken with Vegetable & Almond",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L8. Lemon Chicken",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L9. Sweet & Sour Shrimp",
    price: "$11.95",
    category: ["Special Combo"],
  },
  {
    name: "L10. Sweet & Sour Wonton",
    price: "$9.50",
    category: ["Special Combo"],
  },
  {
    name: "L11. General Tso's Chicken",
    price: "$11.95",
    category: ["Special Combo"],
  },
  // Appetizers
  {
    name: "Egg Roll",
    price: "$2.00",
    category: ["Appetizers"],
  },
  {
    name: "Spring Roll",
    price: "$2.00",
    category: ["Appetizers"],
  },
  {
    name: "Sliced Barbecued Pork",
    price: "$10.95",
    category: ["Appetizers"],
  },
  {
    name: "Honey Garlic Spareribs",
    price: "$13.45",
    category: ["Appetizers"],
  },
  {
    name: "Honey Garlic Chicken Wings",
    price: "$12.50",
    category: ["Appetizers"],
  },
  {
    name: "Jar Doo Chicken Wings",
    price: "$10.95",
    category: ["Appetizers"],
  },
  {
    name: "Dry Garlic Spareribs",
    price: "$11.95",
    category: ["Appetizers"],
  },
  {
    name: "Dumpling",
    price: "$12.95",
    category: ["Appetizers"],
  },
  {
    name: "Beef Teriyaki (10)",
    price: "$15.95",
    category: ["Appetizers"],
  },
  {
    name: "Bo Bo Tray for 2",
    price: "$17.95",
    category: ["Appetizers"],
  },
  {
    name: "Extra Person",
    price: "$9.00",
    category: ["Appetizers"],
  },
  // Lo Mein

  {
    name: "Cantonese Chow Mein",
    price: "$14.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Seafood Cantonese Chow Mein",
    price: "$15.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Lo Mein",
    price: "$14.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Chicken Lo Mein",
    price: "$14.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Beef Lo Mein",
    price: "$14.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Shrimp Lo Mein",
    price: "$14.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Vegetable Lo Mein",
    price: "$12.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Chicken Special Fried Noodles",
    price: "$12.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Beef Special Fried Noodles",
    price: "$12.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Pork Special Fried Noodles",
    price: "$12.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Pan Fried Noodle",
    price: "$5.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Soft Noodle",
    price: "$5.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Shanghai Noodles (No Meat)",
    price: "$13.50",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Chicken Shangai Noodles",
    price: "$13.50",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Beef Shangai Noodles",
    price: "$13.50",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "Pork Shanghai Noodles ",
    price: "$13.50",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Udon Chicken",
    price: "$11.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Udon Beef",
    price: "$11.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Udon Pork",
    price: "$11.95",
    category: ["Lo Mein (Noodle)"],
  },
  {
    name: "New: Beef Black Bean Rice Noodle",
    price: "$12.50",
    category: ["Lo Mein (Noodle)"],
  },
  // Soup
  {
    name: "Wonton Soup",
    price: "$4.50 . . . (Lg) $6.95",
    category: ["Soup"],
  },
  {
    name: "Consomme Soup",
    price: "$3.50 . . . (Lg) $4.00",
    category: ["Soup"],
  },
  {
    name: "Egg Drop Soup",
    price: "$4.50 . . . (Lg) $6.95",
    category: ["Soup"],
  },
  {
    name: "Chicken Noodle Soup",
    price: "$4.50 . . . (Lg) $6.95",
    category: ["Soup"],
  },
  {
    name: "Chicken Rice Soup",
    price: "$4.50 . . . (Lg) $6.95",
    category: ["Soup"],
  },
  {
    name: "Wonton Mein Soup",
    price: "(Lg) $7.50",
    category: ["Soup"],
  },
  {
    name: "Pork Vet Guy Mein Soup",
    price: "(Lg) $10.95",
    category: ["Soup"],
  },
  {
    name: "Deep Fried Wonton Soup",
    price: "$4.50 . . . (Lg) $6.95",
    category: ["Soup"],
  },
  {
    name: "New: Udon Soup",
    price: "$11.95",
    category: ["Soup"],
  },
  // Sweet & Sour
  {
    name: "Sweet & Sour Wontons",
    price: "$10.50",
    category: ["Sweet & Sour"],
  },
  {
    name: "Sweet & Sour Chicken Balls",
    price: "$12.50",
    category: ["Sweet & Sour"],
  },
  {
    name: "Sweet & Sour Spareribs",
    price: "$10.50",
    category: ["Sweet & Sour"],
  },
  {
    name: "Sweet & Sour Shrimp",
    price: "$11.95",
    category: ["Sweet & Sour"],
  },
  // Egg Foo Young
  {
    name: "Mushroom Egg Foo Young",
    price: "$11.95",
    category: ["Egg Foo Young"],
  },
  {
    name: "Chicken Egg Foo Young",
    price: "$11.95",
    category: ["Egg Foo Young"],
  },
  {
    name: "Beef Egg Foo Young",
    price: "$11.95",
    category: ["Egg Foo Young"],
  },
  {
    name: "Barbecued Pork Egg Foo Young",
    price: "$11.95",
    category: ["Egg Foo Young"],
  },
  {
    name: "Shrimp Egg Foo Young",
    price: "$12.95",
    category: ["Egg Foo Young"],
  },

  {
    name: "Tie Pink Egg Foo Young",
    price: "$12.95",
    category: ["Egg Foo Young"],
  },
  // Rice
  {
    name: "Steamed Rice",
    price: "$3.00",
    category: ["Rice"],
  },
  {
    name: "Mushroom Fried Rice",
    price: "$9.50",
    category: ["Rice"],
  },
  {
    name: "Chicken Fried Rice",
    price: "$9.75",
    category: ["Rice"],
  },
  {
    name: "Beef Fried Rice",
    price: "$9.75",
    category: ["Rice"],
  },
  {
    name: "Barbecued Pork Fried Rice",
    price: "$9.75",
    category: ["Rice"],
  },
  {
    name: "Shrimp Fried Rice",
    price: "$10.95",
    category: ["Rice"],
  },
  {
    name: "Tie Pink Special Fried Rice",
    price: "$10.95",
    category: ["Rice"],
  },
  {
    name: "Pan Fried Noodle",
    price: "$6.50",
    category: ["Rice"],
  },
  {
    name: "Soft Noodle",
    price: "$6.50",
    category: ["Rice"],
  },
  // Chop Suey & Chow Mein
  {
    name: "Vegetable Chop Suey",
    price: "$9.95",
    category: ["Chow Mein"],
  },
  {
    name: "Chicken Chop Suey",
    price: "$10.50",
    category: ["Chow Mein"],
  },

  {
    name: "Beef Chop Suey",
    price: "$10.50",
    category: ["Chow Mein"],
  },
  {
    name: "Pork Chop Suey",
    price: "$10.50",
    category: ["Chow Mein"],
  },
  {
    name: "Shrimp Chop Suey",
    price: "$11.50",
    category: ["Chow Mein"],
  },
  {
    name: "Vegetable Chow Mein",
    price: "$10.45",
    category: ["Chow Mein"],
  },
  {
    name: "Chicken Chow Mein",
    price: "$11.00",
    category: ["Chow Mein"],
  },
  {
    name: "Beef Chow Mein",
    price: "$11.00",
    category: ["Chow Mein"],
  },
  {
    name: "Pork Chow Mein",
    price: "$11.00",
    category: ["Chow Mein"],
  },
  {
    name: "Shrimp Chow Mein",
    price: "$12.00",
    category: ["Chow Mein"],
  },
  // Vegetables
  {
    name: "Mixed Vegetables with Almonds",
    price: "$11.50",
    category: ["Vegetables"],
  },
  {
    name: "Mixed Chinese Vegetables",
    price: "$11.50",
    category: ["Vegetables"],
  },
  {
    name: "Chinese Green (Bok Choy)",
    price: "$11.50",
    category: ["Vegetables"],
  },
  {
    name: "Chinese Vegetables with Tofu",
    price: "$11.75",
    category: ["Vegetables"],
  },
  {
    name: "Szechuan Vegetable",
    price: "$11.95",
    category: ["Vegetables"],
  },
  {
    name: "Vegetable Shanghai Noodles",
    price: "$12.95",
    category: ["Vegetables"],
  },
  {
    name: "Vegetable Special Fried Noodles",
    price: "$12.25",
    category: ["Vegetables"],
  },
  // Northern Chinese
  {
    name: "Hot & Spicy Chicken",
    price: "$13.95",
    category: ["Northern Chinese"],
  },
  {
    name: "Hot & Spicy Beef",
    price: "$13.95",
    category: ["Northern Chinese"],
  },
  {
    name: "Sizzling Seafood Plate",
    price: "$15.50",
    category: ["Northern Chinese"],
  },
  {
    name: "Fried Cashew Nuts & Chicken",
    price: "$12.95",
    category: ["Northern Chinese"],
  },
  {
    name: "Fried Cashew Nuts & Shrimp",
    price: "$13.95",
    category: ["Northern Chinese"],
  },
  // Szechuan Dishes
  {
    name: "Chicken Shanghai Noodles",
    price: "$14.95",
    category: ["Szechuan"],
  },
  {
    name: "Szechuan Chicken",
    price: "$12.95",
    category: ["Szechuan"],
  },
  {
    name: "Spicy Chicken",
    price: "$12.95",
    category: ["Szechuan"],
  },

  {
    name: "Kung Po Chicken",
    price: "$12.95",
    category: ["Szechuan"],
  },
  {
    name: "Hunan Chicken",
    price: "$12.95",
    category: ["Szechuan"],
  },
  {
    name: "Beef Shanghai Noodles",
    price: "$14.95",
    category: ["Szechuan"],
  },
  {
    name: "Szechuan Beef",
    price: "$12.95",
    category: ["Szechuan"],
  },
  {
    name: "Spicy Beef",
    price: "$12.95",
    category: ["Szechuan"],
  },

  {
    name: "Kung Po Beef",
    price: "$12.95",
    category: ["Szechuan"],
  },
  {
    name: "Hunan Beef",
    price: "$12.95",
    category: ["Szechuan"],
  },

  {
    name: "Pork Shanghai Noodles",
    price: "$14.95",
    category: ["Szechuan"],
  },
  {
    name: "Szechuan Shrimp",
    price: "$13.95",
    category: ["Szechuan"],
  },

  //Pork
  {
    name: "Pork with Broccoli",
    price: "$12.95",
    category: ["Pork"],
  },
  {
    name: "Pork with Vegetables & Almonds",
    price: "$12.50",
    category: ["Pork"],
  },
  {
    name: "Pork with Green Peppers",
    price: "$12.50",
    category: ["Pork"],
  },
  {
    name: "Pork with Mushrooms",
    price: "$12.95",
    category: ["Pork"],
  },

  //Beef
  {
    name: "Beef with Green Pepper",
    price: "$12.50",
    category: ["Beef"],
  },
  {
    name: "Black Pepper Steak",
    price: "$12.50",
    category: ["Beef"],
  },
  {
    name: "Beef with Vegetables & Almonds",
    price: "$12.50",
    category: ["Beef"],
  },
  {
    name: "Beef with Mushrooms",
    price: "$12.95",
    category: ["Beef"],
  },
  {
    name: "Beef with Broccoli",
    price: "$12.95",
    category: ["Beef"],
  },
  {
    name: "Curry Beef",
    price: "$12.50",
    category: ["Beef"],
  },
  {
    name: "Beef with Mixed Vegetables",
    price: "$12.50",
    category: ["Beef"],
  },
  {
    name: "Beef with Broccoli & Mushrooms",
    price: "$12.95",
    category: ["Beef"],
  },
  {
    name: "Beef with Green Pepper & Mushrooms",
    price: "$12.95",
    category: ["Beef"],
  },

  // Poultry
  {
    name: "Lemon Chicken",
    price: "$12.95",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "General Toa Chicken",
    price: "$13.95",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Sesame Chicken",
    price: "$13.95",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Soo Guy with Almonds",
    price: "$11.95",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Vegetables & Almonds",
    price: "$12.50",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Broccoli",
    price: "$12.95",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Curry Chicken",
    price: "$12.50",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Mixed Vegetables",
    price: "$12.50",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken with Broccoli & Mushrooms",
    price: "$12.95",
    category: ["Poultry (Chicken)"],
  },
  {
    name: "Chicken Mushroom & Green Pepper",
    price: "$12.95",
    category: ["Poultry (Chicken)"],
  },
  //Seafood
  {
    name: "Shrimps with Vegetables & Almonds",
    price: "$13.95",
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Green Pepper & Mushrooms",
    price: "$13.95",
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Mixed Vegetables",
    price: "$13.95",
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Lobster Sauce",
    price: "$13.95",
    category: ["Seafood"],
  },
  {
    name: "Shrimp with Broccoli & Mushrooms",
    price: "$13.95",
    category: ["Seafood"],
  },
  {
    name: "Curry Shrimps",
    price: "$13.95",
    category: ["Seafood"],
  },
  {
    name: "Shrimps with Mushrooms",
    price: "$14.25",
    category: ["Seafood"],
  },
  //Special Suggestions
  {
    name: "Tai Dop Voy",
    price: "$13.50",
    category: ["Special Suggestions"],
  },
  {
    name: "Cantonese Chow Mein",
    price: "$15.50",
    category: ["Special Suggestions"],
  },
  {
    name: "Lo Mein",
    price: "$15.50",
    category: ["Special Suggestions"],
  },
  {
    name: "Chicken Lo Mein",
    price: "$15.50",
    category: ["Special Suggestions"],
  },
  {
    name: "Beef Lo Mein",
    price: "$15.50",
    category: ["Special Suggestions"],
  },
  {
    name: "Shrimp Lo Mein",
    price: "$15.50",
    category: ["Special Suggestions"],
  },
  {
    name: "Moo Goo Guy Pan",
    price: "$12.95",
    category: ["Special Suggestions"],
  },
  {
    name: "So Chow Wonton",
    price: "$12.95",
    category: ["Special Suggestions"],
  },
  {
    name: "General Tso's Chicken",
    price: "$13.95",
    category: ["Special Suggestions"],
  },
  {
    name: "Seafood Delight",
    price: "$15.95",
    category: ["Special Suggestions"],
  },
  {
    name: "Sesame Chicken",
    price: "$13.95",
    category: ["Special Suggestions"],
  },
  {
    name: "Tie Pink's Special Chop Suey",
    price: "$13.25",
    category: ["Special Suggestions"],
  },
  {
    name: "Black Pepper Steak",
    price: "$12.95",
    category: ["Special Suggestions"],
  },
  {
    name: "Seafood Cantonese Chow Mein",
    price: "$15.95",
    category: ["Special Suggestions"],
  },
  {
    name: "Curry Rice Vermicelli",
    price: "$13.75",
    category: ["Special Suggestions"],
  },
  {
    name: "Chicken Special Fried Noodles",
    price: "$13.25",
    category: ["Special Suggestions"],
  },
  {
    name: "Beef Special Fried Noodles",
    price: "$13.25",
    category: ["Special Suggestions"],
  },
  {
    name: "Pork Special Fried Noodles",
    price: "$13.25",
    category: ["Special Suggestions"],
  },
  // Family Dinners
  {
    name: "Deluxe Dinner for 2",
    price: "$40.95",
    items: [
      "Bo Bo Tray",
      "Sweet & Sour Chicken Balls",
      "Beef with Vegetable & Almonds",
      "Chicken Fried Rice",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Special Dinner for 2",
    price: "$40.95",
    items: [
      "2 Egg Rolls",
      "General Tso's Chicken",
      "Chicken Fried Rice",
      "Cantonese Chow Mein",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 2",
    price: "$29.50",
    items: [
      "2 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Beef Chop Suey",
      "Chicken Fried Rice",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 3",
    price: "$41.95",
    items: [
      "3 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Chicken with Vegetable & Almonds",
      "Beef Chop Suey",
      "Chicken Fried Rice",
      "Fortune Cooks",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 4",
    price: "$54.95",
    items: [
      "4 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Beef Chow Mein",
      "Cantonese Chow Mein",
      "Chicken Fried Rice (2 Orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 5",
    price: "$68.95",
    items: [
      "4 Egg Rolls",
      "Sweet & Sourc Chicken Balls",
      "Beef with Green Pepper",
      "Breaded Shrimp with Lemon",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "Chicken Fried Rice (2 orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 6",
    price: "$75.95",
    items: [
      "6 Egg Rolls",
      "Sweet & Sour Chicken Balls",
      "Breaded Shrimp with Lemon",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "So Chow Wonton",
      "Chicken Fried Rice (2 orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 8",
    price: "$110.95",
    items: [
      "8 Egg Rolls",
      "Sweet & Sour Chicken (2 orders)",
      "Sweet & Sour Shrimp (2 orders)",
      "Chicken with Vegetable Almond",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "Chicken Fried Rice (2 orders)",
      "Jar Doo Chicken Wings",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
  {
    name: "Dinner for 10",
    price: "$135.95",
    items: [
      "10 Egg Rolls",
      "Sweet & Sour Chicken Balls (2 orders)",
      "Sweet & Sour Shrimp (2 orders)",
      "Beef with Broccoli (2 orders)",
      "General Tso's Chicken (2 orders)",
      "Cantonese Chow Mein",
      "Beef Chop Suey",
      "Chicken Fried Rice (2 orders)",
      "Fortune Cookies",
    ],
    category: ["Family Dinners"],
  },
];
