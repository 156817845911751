import React, { useState, useEffect } from "react";
import Pattern from "../Assets/Images/Pattern.png";
import Logo from "../Assets/Images/Logo.png";
import { NavLink } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return {
    width: 0,
    height: 0,
  };
}
export function useWindowDimensions() {
  const [windowdimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowdimensions;
}

function Nav(props) {
  const { width, height } = useWindowDimensions();
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (width >= 1100) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [width]);

  return (
    <div
      className="nav-container"
      style={{
        transform: props.navOn
          ? "translateX(0)"
          : isDesktop
          ? "translateX(0)"
          : "translateX(-100%)",
      }}
    >
      <div className="nav-pattern-1">
        <img src={Pattern} alt="" />
      </div>
      <div className="nav-pattern-2">
        <img src={Pattern} alt="" />
      </div>
      <div
        className="nav-close-container"
        onClick={() => {
          props.closeNav();
        }}
      >
        <AiOutlineClose />
      </div>
      <div
        className="nav-open-container"
        onClick={() => {
          props.openNav();
        }}
      >
        <AiOutlineMenu />
      </div>
      <div className="nav-links-container">
        <div className="nav-logo-container">
          <img className="nav-logo" src={Logo} alt="" />
        </div>
        <div className="text-links">
          <NavLink
            className="link-outline"
            activeClassName
            to="/"
            onClick={() => {
              props.closeNav();
            }}
          >
            <p className="nav-link">Home</p>
          </NavLink>
          <NavLink
            className="link-outline"
            activeClassName
            to="/menu"
            onClick={() => {
              props.closeNav();
            }}
          >
            <p className="nav-link">Menu</p>
          </NavLink>
          <NavLink
            className="link-outline"
            activeClassName
            to="/contact"
            onClick={() => {
              props.closeNav();
            }}
          >
            <p className="nav-link">Contact</p>
          </NavLink>
        </div>
      </div>
      {/* <div className="update-disclaimer">*** Please Order in advance for the holidays ***</div> */}
    </div>
  );
}

export default Nav;
